.raw_logo {
  height: 305;
  width: 440;
}

@media (max-width: 767px) {
  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
  }
}
