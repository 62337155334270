.education-main {
  width: 100%;
}

.basic-education {
  margin-left: 5%;
  margin-right: 5%;
}

.heading-div {
  display: flex;
}

.heading-div > * {
  flex: 1;
}

.heading-img-div {
  align-items: center;
  justify-content: center;
}

.heading-text-div {
  text-align: center;
}

.heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 100px;
}

.heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
}

@media (max-width: 1380px) {
  .heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .heading-sub-text {
    font-size: 20px;
  }

  .heading-div {
    flex-direction: column;
  }
}



/* --------- */
.resume-section {
  position: relative !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 10px;
  padding-bottom: 50px;
}


.date{
  font-size: .9em;
  padding-left: .5em;
}

.resume-subtitle {
  font-size: 1em;
  font-weight: 700;
  margin: 0;
} 
.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-bottom: 30px;
  text-align: center;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #1179f7;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #ffa500;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  color:black;
  margin-bottom: 10px;
  margin-top: -2px;
}


.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #1179f7;
  border: 2px solid #1179f7;
}